<template>
<div class="col-xl-6 configurator__param">
  <h2 class="title-secondary bold-weight mb-3">Ihre aktuelle Vorauswahl:</h2>
  <div class="hide-list">
    <div class="configurator-param">
      <div v-for="(item, index) in limitedLists" :key="index" class="configurator-param__item">
        <span v-if="index < limit">
          <div class="title-secondary">{{ item.name }}:</div>
          <a href="#" class="link configurator-param__edit">{{ item.selectedLabel }}</a>
        </span>
      </div>
    </div>
    <button class="btn-reset btn-open-more" @click="moreAndLess(steps.length)">
      <i class="fas fa-angle-down me-1"></i>
      <span v-if="!showShortList" >Mehr</span>
      <span v-else>Minimieren</span>
    </button>
  </div>
</div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    const showShortList = ref(false)
    const limit = ref(5)
    const defaultLimit = ref(5)
    // computed
    const steps = computed(() => {
      return store.getters['configurator/steps']
    })
    const limitedLists = computed(() => {
      if (!showShortList.value) {
        return steps.value.slice(0, defaultLimit.value)
      }
      return steps.value
    })
    // actions
    function moreAndLess (filtersLength) {
      showShortList.value = !showShortList.value
      limit.value = filtersLength
    }
    return {
      steps,
      limit,
      showShortList,
      defaultLimit,
      limitedLists,
      moreAndLess
    }
  }
}
</script>
